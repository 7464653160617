import { Sha256 } from "@aws-crypto/sha256-js";
import { fromWebToken } from "@aws-sdk/credential-providers";
import { HttpRequest } from "@aws-sdk/protocol-http";
import { SignatureV4 } from "@aws-sdk/signature-v4";
import { AwsCredentialIdentityProvider } from "@aws-sdk/types/dist-types/identity/AwsCredentialIdentity";
import axios from "axios";
import { add, isBefore } from "date-fns";
import React, { ReactElement, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { InteractiveHappinessAdviceResponse } from "../../../models/InteractiveHappinessAdvice";
import { InteractiveWorkChatResponse } from "../../../models/InteractiveWorkChat";
import { getRequestAsync, postRequestAsync } from "../../../utils/api_client";
import Chat from "./chat";

interface Props {
  interactiveHappinessAdvice: InteractiveHappinessAdviceResponse;
  registedChatCount: number;
  userMessage?: string;
  lambdaFunctionUrlDomain: string;
  awsAccessRoleName: string;
  onChange: () => void;
  onFinish: (createdChats: InteractiveWorkChatResponse[]) => void;
  afterRegisted: () => void;
  onExcessLengthError: () => void;
}

const getOpenIdToken = async (): Promise<string | undefined> => {
  const { result } = await postRequestAsync<{ token: string }>(
    "/user/aws/get_open_id_token"
  );
  if (result !== undefined) {
    return result.token;
  }
};

export default function ChatInProgress(props: Props): ReactElement | null {
  const {
    registedChatCount,
    interactiveHappinessAdvice,
    userMessage,
    lambdaFunctionUrlDomain,
    awsAccessRoleName,
    onChange,
    onFinish,
    afterRegisted,
  } = props;
  const [credentialObject, setCredentialObject] = useState<{
    credential: AwsCredentialIdentityProvider;
    expireTime: Date;
  }>();
  const [loading, setLoading] = useState(false);
  const [assistantMessage, setAssistantMessage] = useState("");
  const [isFinished, setIsFinished] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    if (userMessage !== undefined) {
      loadAssistantMessage();
    }
  }, [userMessage]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (retryCount > 0) {
      if (retryCount >= 3) {
        // リトライ回数超過
        // APIのレスポンスパラメータ仕様がいきなり変わったのかわからないが、この分岐から抜け出せず対話ワーク自体が動かなくなる事象が発生したので、
        // この分岐に入ったらハッピーAIがちゃんと動いていることの確認を促すようなエラーメッセージにしている。
        window.Raven.captureException(
          new Error(
            "ハッピーAIの幸福度向上アドバイスで不具合が起きている可能性があります。確認してください。"
          )
        );
        window.alert(
          "ハッピーAIに問題が発生しました。すみませんがブラウザをリロードして再実行してみてください。"
        );
      } else {
        timeoutId = setTimeout(() => {
          setAssistantMessage("");
          loadAssistantMessage();
        }, 1000);
      }
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [retryCount]);

  useEffect(() => {
    const finished = async () => {
      initStates();
      onFinish(
        userMessage === undefined
          ? [
              {
                id: uuidv4(),
                role: "assistant_role",
                message: assistantMessage,
              },
            ]
          : [
              { id: uuidv4(), role: "user_role", message: userMessage },
              {
                id: uuidv4(),
                role: "assistant_role",
                message: assistantMessage,
              },
            ]
      );
      await postRequestAsync(
        `/user/interactive_happiness_advices/${interactiveHappinessAdvice.id}/interactive_happiness_advice_chats`,
        {
          interactive_happiness_advice_chat: {
            user_message: userMessage,
            assistant_message: assistantMessage,
          },
        }
      );
      afterRegisted();
    };
    if (isFinished) {
      finished();
    }
  }, [isFinished]);

  const initStates = () => {
    setIsFinished(false);
    setLoading(false);
    setAssistantMessage("");
    setRetryCount(0);
  };

  const checkConsistency = async (): Promise<boolean> => {
    const { result } = await getRequestAsync<{ count: number }>(
      `/user/interactive_happiness_advices/${interactiveHappinessAdvice.id}/interactive_happiness_advice_chats/count`
    );
    if (result !== undefined) {
      return result.count === registedChatCount;
    }
    return false;
  };

  const getCredential = async (): Promise<
    AwsCredentialIdentityProvider | undefined
  > => {
    if (
      credentialObject !== undefined &&
      isBefore(new Date(), credentialObject.expireTime)
    ) {
      return credentialObject.credential;
    }
    const openIdToken = await getOpenIdToken();
    if (openIdToken === undefined) {
      return;
    }
    const c = fromWebToken({
      roleArn: awsAccessRoleName,
      webIdentityToken: openIdToken,
    });
    setCredentialObject({
      credential: c,
      expireTime: add(new Date(), { minutes: 10 }),
    });
    return c;
  };

  const loadAssistantMessage = async (): Promise<void> => {
    setLoading(true);
    const isValid = await checkConsistency();
    if (!isValid) {
      setLoading(false);
      window.alert(
        "チャットが古くなっている可能性があります。申し訳ありませんが、ブラウザをリロードしてください"
      );
      return;
    }
    const c = await getCredential();
    if (c === undefined) {
      window.alert(
        "認証情報が取得できませんでした。ブラウザをリロードして再度お試しください"
      );
      setLoading(false);
      return;
    }
    const signer = new SignatureV4({
      region: "ap-northeast-1",
      service: "lambda",
      sha256: Sha256,
      credentials: c,
    });

    // const encoder = new TextEncoder();
    const requestBody = {
      question: userMessage,
    };
    const req = await signer.sign(
      new HttpRequest({
        method: "POST",
        path: "/",
        hostname: lambdaFunctionUrlDomain,
        headers: {
          "Content-Type": "application/json",
          host: lambdaFunctionUrlDomain,
        },
        body: JSON.stringify(requestBody),
      })
    );
    const h = req.headers;
    delete h["host"];
    try {
      await axios({
        url: `https://${req.hostname}${req.path}`,
        method: "post",
        data: requestBody,
        headers: h,
        timeout: 100000,
        onDownloadProgress: (progressEvent) => {
          if (progressEvent.target.status !== 200) {
            return;
          }
          const dataChunk = progressEvent.target.response;
          if (!dataChunk.includes("Runtime.StreamError")) {
            setAssistantMessage((_) => dataChunk);
          }
          onChange();
        },
      });
      setIsFinished(true);
    } catch (e) {
      console.log(e);
      window.Raven.captureException(e);
      if (e.message === "Lambda function error") {
        window.alert(
          "エラーが発生しました。しばらく時間をおいてお試しください。"
        );
      } else {
        window.alert(
          "エラーが発生しました。ブラウザをリロードして再実行してみてください。"
        );
      }
    }
  };

  return (
    <>
      {userMessage !== undefined && (
        <Chat role="user_role" body={userMessage} />
      )}
      {loading && (
        <Chat role="assistant_role" body={assistantMessage} loading />
      )}
    </>
  );
}
